<template>
  <div style="padding-bottom: 200px">
    <form @submit.prevent="submitHandler" v-form-enter v-if="!isLoading">
      <div
        class="formgrid p-fluid grid justify-content-around align-items-center"
      >
        <div class="field col-6">
          <h2>
            <template v-if="uid">Edit</template
            ><template v-else>Add</template> Account
          </h2>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup flex justify-content-end">
            <AActiveSwitch
              v-model="accountStructureInfo.active"
              class="green"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Account Code</label>
          <div class="p-inputgroup">
            <InputText
              v-model="accountStructureInfo.account_code"
              placeHolder="Account Code"
            />
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label>Account Type</label>
          <div class="p-inputgroup">
            <div class="p-inputgroup">
              <Dropdown
                :options="accountTypes"
                placeholder="Account Type"
                class="input"
                v-model="accountStructureInfo.account_type"
              />
            </div>
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.accountStructureInfo.account_type.required"
          >
            Account Type is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Account Name</label>
          <div class="p-inputgroup">
            <InputText
              v-model="accountStructureInfo.account_name"
              placeHolder="Account Name"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.accountStructureInfo.account_name.required"
          >
            Account Name is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Integration Reference</label>
          <div class="p-inputgroup">
            <InputText
              v-model="accountStructureInfo.integration_reference"
              placeHolder="Integration Reference"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-content-between pt-5">
        <div>
          <Button type="submit"> SAVE </Button>
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad :full="true" v-else />
  </div>
</template>
<script>
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
export default {
  name: "ContactSlider",
  components: {},
  data() {
    return {
      submitted: false,
      accountTypes: ["Sales", "Cos"],
      isLoading: null,
      loadingText: "Loading...",
      accountStructureInfo: {
        account_code: null,
        account_name: null,
        account_type: null,
        integration_reference: null,
        active: true,
      },
    };
  },
  validations: {
    accountStructureInfo: {
      account_code: {
        required,
      },
      account_type: {
        required,
      },

      account_name: {
        required,
      },
    },
  },
  async mounted() {
    if (this.selectedObject) {
      this.accountStructureInfo.active =
        this.selectedObject.active === 1 ? true : false;
      this.accountStructureInfo.account_code = this.selectedObject.gl_code;
      this.accountStructureInfo.account_name = this.selectedObject.gl_name;
      this.accountStructureInfo.account_type = this.changeLetterCase(
        this.selectedObject.account_type,
        "upperCase"
      );
      this.accountStructureInfo.integration_reference =
        this.selectedObject.integration_reference;
    }
  },
  methods: {
    changeLetterCase(text, type) {
      if (type === "upperCase") {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
      if (type === "lower") {
        return text.charAt(0).toLowerCase() + text.slice(1);
      }
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.accountStructureInfo.account_type = this.changeLetterCase(
          this.accountStructureInfo.account_type,
          "lower"
        );

        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/departments/gls" +
              (!this.selectedObject ? "" : "/" + this.uid),
            JSON.stringify(this.accountStructureInfo),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
            this.$toast.add({
              severity: "success",
              summary: "Account Updated",
              life: 3000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    uid() {
      return this.selectedObject?.uid;
    },
  },
};
</script>